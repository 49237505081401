import api from '../../../redux/api';
import { CreatePayment } from '../types/CreatePayment';
import { Billing, Product, Subscription } from '../types/SubscriptionHistory';
import { ApiPagination } from '@hdcorner/ui-library';

const subscriptionsQueries = api.injectEndpoints({
  endpoints: builder => ({
    createPayment: builder.mutation<
      { url: string; transaction: string },
      CreatePayment & { productId: string }
    >({
      query: ({ productId, ...data }) => ({
        method: 'POST',
        body: { ...data },
        url: `/payments/viva/createPayment/${productId}`,
      }),
      invalidatesTags: ['AuthUser', 'UserData'],
    }),

    redeemCode: builder.mutation<
      void,
      {
        code: string;
        firstName: string;
        lastName: string;
        email?: string;
        phoneNumber?: string;
      }
    >({
      query: body => ({
        method: 'POST',
        body: { ...body },
        url: `/payments/redeem-code`,
      }),
      invalidatesTags: ['AuthUser', 'UserData'],
    }),

    getTransaction: builder.query<
      { status: 'prepared' | 'success' | 'failed' },
      { transactionId: string }
    >({
      query: ({ transactionId }) => `/payments/transaction/${transactionId}`,
    }),

    getBillingHistory: builder.query<{ transactions: Billing[] }, ApiPagination>({
      query: ({ skip, limit }) => ({
        method: 'GET',
        url: '/payments/transactions',
        params: { skip, limit, populate: ['products.product'] },
      }),
    }),

    getSubscriptions: builder.query<Subscription[], void>({
      query: () => ({
        method: 'GET',
        url: '/payments/subscriptions',
        params: { populate: ['product'] },
      }),
    }),

    deleteSubscription: builder.mutation<void, { productId: string }>({
      query: ({ productId }) => ({
        method: 'DELETE',
        url: `/payments/subscription/${productId}`,
      }),
    }),

    getProducts: builder.query<Product[], void>({
      query: () => ({
        method: 'GET',
        url: '/payments/products',
      }),
    }),
  }),
});

export const {
  useDeleteSubscriptionMutation,
  useLazyGetTransactionQuery,
  useGetBillingHistoryQuery,
  useGetSubscriptionsQuery,
  useCreatePaymentMutation,
  useRedeemCodeMutation,
  useGetProductsQuery,
} = subscriptionsQueries;

export default subscriptionsQueries;
