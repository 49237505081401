import { HistoryHourGlassIcon, ModalRedeemCode, theme } from '@hdcorner/ui-library';
import { useIonRouter } from '@ionic/react';
import { Box, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react';
import PageLayout from '../../components/PageLayout';
import { useGetAuthUserQuery } from '../authentication/queries/signInQueries';
import SubscriptionPanelMobile from './components/SubscriptionPanelMobile';
import SubscriptionPanelWeb from './components/SubscriptionPanelWeb';
import { useGetUserDataQuery } from '../profile/queries/personalInfoQueries';
import ModalCancelSub from './components/ModalCancelSub';
import { subscriptionBenefits } from './constants';
import useAlert from '../../hooks/useAlert';
import { useTranslation } from 'react-i18next';
import {
  useDeleteSubscriptionMutation,
  useGetSubscriptionsQuery,
  useRedeemCodeMutation,
} from './queries/subscriptionsQueries';
import { useConvertJsonSubs } from '../../utils/useConvertJSON';

const Subscription = () => {
  const router = useIonRouter();
  const { presentSuccess, presentError } = useAlert();
  const { t } = useTranslation();

  const web = useMediaQuery(theme.breakpoints.up('md'));
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  const [open, setOpen] = useState<boolean>(false);
  const [cancelSub, setCancelSub] = useState<boolean>(false);
  const [userDetails, setUserDetails] = useState({
    lastName: '',
    firstName: '',
    premiumUser: false,
    profilePicture: '',
  });

  const [redeemCode] = useRedeemCodeMutation();
  const [deleteSubscription] = useDeleteSubscriptionMutation();

  const { data: subscriptionsRes } = useGetSubscriptionsQuery();
  const { data: authUser, error: errorAuthUser } = useGetAuthUserQuery();
  const { data: userData, error: errorUserData } = useGetUserDataQuery();

  useEffect(() => {
    if (errorAuthUser || errorUserData) presentError(t('errors.profile.errorUserData'));
  }, [errorAuthUser, errorUserData]);

  useEffect(() => {
    if (authUser) {
      const user = authUser[0];

      const newAuthUser = {
        lastName: user.lastName,
        firstName: user.firstName,
        profilePicture: user.profilePicture ? user.profilePicture.url : '',
      };

      setUserDetails({ ...userDetails, ...newAuthUser });
    }
  }, [authUser]);

  useEffect(() => {
    if (userData && userData.length > 0) {
      const user = userData[0];
      setUserDetails(oldData => ({ ...oldData, premiumUser: user.premiumUser || false }));
    }
  }, [userData]);

  const handleTryPremium = () => {
    router.push('/dashboard/subscription/checkout?trial=true');
  };

  const handlePlanUpgrade = () => {
    router.push('/dashboard/subscription/checkout');
  };

  const handlePlanDowngrade = () => {
    setCancelSub(true);
  };

  const handleReturnToBasic = () => {
    setCancelSub(false);
    const activeSubscription = subscriptionsRes?.find(it => it.status === 'active');
    if (!activeSubscription) {
      presentError('No active subscription found');
      return;
    }
    deleteSubscription({ productId: activeSubscription._id })
      .unwrap()
      .then(() => {
        presentSuccess(t('errors.subscription.successUpgrade'));
      })
      .catch(e => {
        console.log(e);
        presentError(t('errors.subscription.errorUpgrade'));
      });
  };

  const handleModalSaveClick = (data: { code: string }) => {
    if (!authUser) {
      presentError(t('errors.subscription.errorRedeem'));
      return;
    }
    const user = authUser[0];
    const body = {
      code: data.code,
      email: user.email || '',
      phoneNumber: user.phoneNumber,
      lastName: userDetails.lastName,
      firstName: userDetails.firstName,
    };
    redeemCode(body)
      .unwrap()
      .then(() => {
        presentSuccess(t('errors.subscription.successRedeem'));
        setOpen(false);
      })
      .catch(() => {
        presentError(t('errors.subscription.errorRedeem'));
        setOpen(false);
      });
  };

  const handleCancelSubModal = () => {
    setCancelSub(false);
  };

  const handleViewHistory = () => {
    router.push('/dashboard/subscription/history');
  };

  return (
    <PageLayout
      backgroundWhite
      defaultHref={'/dashboard/profile'}
      headerTitle={t('subscription.titles.sub')}
      endButtons={[
        {
          onClick: handleViewHistory,
          icon: <HistoryHourGlassIcon width={'24px'} height={'24px'} />,
        },
      ]}
    >
      <Box hidden={web}>
        <SubscriptionPanelMobile
          isPremium={userDetails.premiumUser}
          benefits={useConvertJsonSubs(subscriptionBenefits)}
        />
      </Box>
      <Box hidden={mobile} height={'100%'}>
        <SubscriptionPanelWeb
          premium={userDetails.premiumUser}
          avatar={userDetails.profilePicture}
          handleTryPremium={handleTryPremium}
          handlePlanUpgrade={handlePlanUpgrade}
          handleViewHistory={handleViewHistory}
          handleRedeem={() => setOpen(true)}
          handlePlanDowngrade={handlePlanDowngrade}
          benefits={useConvertJsonSubs(subscriptionBenefits)}
          user={`${userDetails.firstName} ${userDetails.lastName}`}
          planBasic={userDetails.premiumUser ? 'premium' : 'basic'}
          planPremium={userDetails.premiumUser ? 'premium' : 'basic'}
        />
      </Box>
      <ModalRedeemCode
        open={open}
        setOpen={setOpen}
        errorMessage={'default'}
        handleModalSaveClick={handleModalSaveClick}
      />
      <ModalCancelSub
        web={web}
        open={cancelSub}
        setOpen={setCancelSub}
        handleStayPremium={handleCancelSubModal}
        handleCancelSubscription={handleReturnToBasic}
      />
    </PageLayout>
  );
};

export default Subscription;
